// import { io } from "socket.io-client";
import { createContext, ReactElement, useState } from "react";
import { Book, BookProps, BookValues } from "../types";

export const BookContext = createContext({} as BookValues);

const BookContextProvider = (props: BookProps): ReactElement<BookValues> => {
  const [selectedBook, setSelectedBook] = useState<Book>();
  const [books, setBooks] = useState<Book[]>([]);
  const [noFoBots, setNoOfBots] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [adminBooks, setAdminBooks] = useState<Book[]>([]);
  const [book, setBook] = useState<Book>();
  const [response, setResponse] = useState("");
  const [showAddMoreFileBox, setShowAddMoreFileBox] = useState(false);
  const [showAnswerSourcePopup, setShowAnswerSourcePopup] = useState(false);
  const [selectedSource, setSelectedSource] = useState<any>();
  const [noOfTeamBooks, setNoOfTeamBooks] = useState<number>(0);

  const deleteDataSource = (bookId: string, sourceId: string) => {
    setSelectedBook((oldBookState: Book | undefined) => {
      if (oldBookState) {
        if (bookId === (oldBookState?._id || oldBookState?.id)) {
          return {
            ...oldBookState,
            sources: oldBookState.sources?.filter(
              (sourc) => sourc._id !== sourceId
            ),
          };
        }
      }

      return oldBookState;
    });
  };

  const updateBookIndex = (book: Book) => {
    setSelectedBook((oldBookState: Book | undefined) => {
      if (oldBookState) {
        if (book._id === (oldBookState?._id || oldBookState?.id)) {
          return {
            ...oldBookState,
            indexStatus: book.indexStatus,
            sources: book.sources,
          };
        }
      }

      return oldBookState;
    });

    setBooks((oldBooks: Book[]) => {
      const mappedBooks = oldBooks.map((bk) =>
        bk._id === book.id
          ? {
              ...bk,
              indexStatus: book.indexStatus,
              roundProgressPercentage: book.roundProgressPercentage,
              chatbotId: book.chatbotId,
            }
          : bk
      );
      return mappedBooks;
    });

    setAdminBooks((oldBooks: Book[]) => {
      const mappedBooks = oldBooks.map((bk) =>
        bk._id === book.id
          ? {
              ...bk,
              indexStatus: book.indexStatus,
              roundProgressPercentage: book.roundProgressPercentage,
              chatbotId: book.chatbotId,
            }
          : bk
      );
      return mappedBooks;
    });
  };

  const updateBookTitle = (bookId: string, title: string) => {
    setSelectedBook((oldBookState: Book | undefined) => {
      if (oldBookState) {
        if (bookId === (oldBookState?._id || oldBookState?.id)) {
          return {
            ...oldBookState,
            title: title,
          };
        }
      }

      return oldBookState;
    });

    setBooks((oldBooks: Book[]) => {
      const mappedBooks = oldBooks.map((bk) =>
        bk._id === bookId
          ? {
              ...bk,
              title: title,
            }
          : bk
      );
      return mappedBooks;
    });
  };

  const updateBookInterfaceType = (bookId: string, interfaceType: string) => {
    setSelectedBook((oldBookState: Book | undefined) => {
      if (oldBookState) {
        if (bookId === (oldBookState?._id || oldBookState?.id)) {
          return {
            ...oldBookState,
            interfaceType: interfaceType,
          };
        }
      }

      return oldBookState;
    });

    setBooks((oldBooks: Book[]) => {
      const mappedBooks = oldBooks.map((bk) =>
        bk._id === bookId
          ? {
              ...bk,
              interfaceType: interfaceType,
            }
          : bk
      );
      return mappedBooks;
    });
  };
  return (
    <BookContext.Provider
      value={{
        books,
        setBooks,
        adminBooks,
        setAdminBooks,
        noFoBots,
        setNoOfBots,
        book,
        setBook,
        response,
        setResponse,
        updateBookIndex,
        selectedBook,
        setSelectedBook,
        setShowAddMoreFileBox,
        showAddMoreFileBox,
        showAnswerSourcePopup,
        setShowAnswerSourcePopup,
        updateBookInterfaceType,
        updateBookTitle,
        deleteDataSource,
        totalPage,
        setTotalPage,
        selectedSource,
        noOfTeamBooks,
        setNoOfTeamBooks,
        setSelectedSource,
      }}
    >
      {props.children}
    </BookContext.Provider>
  );
};

export default BookContextProvider;
