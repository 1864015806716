import { Field, Form, Formik } from "formik";
import { AutoComplete } from "primereact/autocomplete";
import { useContext, useEffect, useState } from "react";
import { Button, Col, FormLabel, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { Document, pdfjs } from "react-pdf";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { MEDIUM, MOBILE, TAB } from "../../constants/breakpoints";
import { BookContext } from "../../context/BookContext";
import { UserContext } from "../../context/UserContext";
import { ChooseURLContext } from "../../context/choose-url-context";
import { LoaderContext } from "../../context/loader-context";
import { PlanContext } from "../../context/plan-context";
import { USER_ADD_A_BOOK } from "../../customer-io/events";
import {
  ChatBotEvents,
  EventCategory,
  Source,
  SubCategory,
} from "../../event-utils/dropchat-event-enums";
import {
  getDeviceInfoForTrackEvent,
  getUsersEmail,
} from "../../event-utils/dropchat-event-track";
import { trackEvent } from "../../event-utils/track-event";
import FRMAEIMAGE from "../../images/Frame 812.png";
import OrIconImage from "../../images/oricon.svg";
import { Mixpanel } from "../../mixpanel";
import myAxios from "../../myaxios";
import { Book } from "../../types";
import { CREATE_BOOK } from "../../utils/class-names";
import {
  isTestUser,
  matchYoutubeUrl,
  parseErrorResponse,
} from "../../utils/utils";
import UrlSelectionBox from "../utility-component/url-selection-box";
import { TeamContext } from "../../context/team-context";
import UploadComponent from "../utility-component/text-drag-drop-component";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

declare var _cio: any;

const FieldSection = styled.div`
  margin-bottom: 10px;
  flex-direction: column;
  & label {
    @media (max-width: ${MOBILE.maxWidth}px) {
      font-size: 12px;
    }
    @media (min-width: ${TAB.minWidth}px) {
      font-size: 14px;
    }
    @media (min-width: ${MEDIUM.minWidth}px) {
      font-size: 16px;
    }
  }
`;

const BookFetchForm = styled(Form)`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
`;
const FormError = styled.div`
  margin: 0rem 0rem 1rem;
  text-align: left;
  color: red;
`;

const FormInput = styled(Field)`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #33303c99;
  background: linear-gradient(0deg, #ffffff, #ffffff);

  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 14px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 16px;
  }
`;

const SavePDFButton = styled(Button)`
  background: #1585b5;
  width: 55%;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
  &:hover {
    background: #0b5f82;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 50%;
    font-size: 12px;
  }
`;

const FormLabelDocInput = styled(FormLabel)`
  width: 35%;
  height: 70%;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #1585b5;
  background: linear-gradient(
    0deg,
    rgba(21, 133, 181, 0.2),
    rgba(21, 133, 181, 0.2)
  );
  display: flex;
  align-items: center;
  justify-content: center;
  color: #33303ccc;
  font-weight: 500;

  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 8rem;
    height: 5vh;
    align-items: center;
    justify-content: center;
  }
`;

const UpgradePDfSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  padding: 5px 25px;
  /* gap: 10px; */
  background: #f2ae01;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* line-height: 14px; */
  color: #ffffff;
  cursor: pointer;
  margin-left: 5px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
  }
`;

const PlanSpan = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: red;
  margin-left: 5px;
  margin-top: 8px;
`;

const MainLabel = styled.label`
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-left: 5px;
    padding-left: 5px;
    display: flex;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-left: 5px;
    padding-left: 5px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-left: 10px;
    padding-left: 10px;
  }
`;

const FormLabelSpan = styled.span`
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-left: 2px;
    font-size: 12px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-left: 4px;
    font-size: 13px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-left: 6px;
    font-size: 14px;
  }
`;

const SelectedCount = styled.span`
  font-size: 12px;
  font-weight: 400;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 10px;
  }
`;

const ColPDFMainSubSection = styled(Col)`
  align-items: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    flex-direction: column;
    align-items: start;
  }
`;

const FieldS = styled(Field)`
  cursor: pointer;
`;

const ColForPDFSelection = styled(Col)`
  flex-direction: column;
  padding-left: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 60px;
  justify-content: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    justify-content: start;
    padding-left: 0;
  }
`;

const FormInputTextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  height: 8rem;
`;

const PDFNameFormLabel = styled(FormLabel)`
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #33303c;

  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 13px;
    letter-spacing: 0.9px;
    margin-bottom: -1px;
    font-weight: 600;
    width: 86vw !important;
  }
`;
const SubmitYoutubeIcon = styled.img`
  position: absolute;
  right: 15px;
  width: 38px;
  cursor: pointer;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 33px;
  }
`;

const TextAreaLimitSpan = styled.span`
  max-width: max-content;
  padding: 2px 10px;
  background: #1585b5;
  color: #fff;
  font-size: 12px;
`;

const CreateDropchatBook = ({
  setAllowedInputsForUser,
  setTotalPage,
  setResetPagination,
  setNoOfTeamBooks,
}: any) => {
  const { selectedTeam } = useContext(TeamContext);
  const { setBooks, books } = useContext(BookContext);
  const {
    setShowConfirmationBox,
    setUrlList,
    setCreateBookInput,
    setResetForm,
  } = useContext(ChooseURLContext);
  const {
    user,
    setTotalBooksCount,
    updateUserSubscriptionIndexCount,
    setGuestUserInfo,
  } = useContext(UserContext);
  const { setShowLoader } = useContext(LoaderContext);
  const [pdfCountLimit, setPdfCountLimit] = useState<Boolean>(false);
  const [totalTextCharacterCount, setTotalTextCharacterCount] = useState(0);
  const [selectedRadioOption, setSelectedRadioOption] = useState<any>("file");
  const [options, setOptions] = useState([]);
  // eslint-disable-next-line
  const [query, setQuery] = useState<any>();
  const [lastPdfPageLimitStatus, setLastPdfPageLimitStatus] =
    useState<Boolean>(false);
  // eslint-disable-next-line
  const [numPages, setNumPages] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { setPlanHighliter } = useContext(PlanContext);

  const [fileObject, setFileObject] = useState<any>();
  const [isSitemapSelected, setIsSitemapSelected] = useState(false);
  const [popupUrlContent, setPopupUrlContent] = useState({
    type: "",
    url: "",
  });
  const [characterCount, setCharacterCount] = useState(0);
  const [ytUrlCharacterCount, setYtUrlCharacterCount] = useState(0);
  const [disablePdfIndexLimit, setDisablePdfIndexLimitState] =
    useState<boolean>(false);
  const [disableYTUrlIndexLimit, setDisableYTUrlIndexLimitState] =
    useState<boolean>(false);
  const [disableTextIndexLimit, setDisableTextIndexLimitState] =
    useState<boolean>(false);
  // const [disableCharacterIndexLimit, setDisableCharacterIndexLimitState] =
  //   useState<boolean>(false);
  const [isTeamOwner, setIsTeamOwner] = useState<boolean>();

  useEffect(() => {
    // console.log("selectedTeam", selectedTeam);
    if (selectedTeam) {
      let currentTeamMember: any =
        selectedTeam &&
        selectedTeam.members &&
        selectedTeam?.members.find((m: any) => m.email === user?.email);
      // console.log("currentTeamMember is, ", currentTeamMember);
      if (currentTeamMember && currentTeamMember.role !== "owner") {
        setIsTeamOwner(false);
      } else {
        setIsTeamOwner(true);
      }
    }
    // eslint-disable-next-line
  }, [selectedTeam]);

  const [ytUrl, setYTUrl] = useState("");

  const checkPdfPageLimit = (count: number) => {
    if (
      user &&
      user.currentSubscription &&
      user.currentSubscription.meta &&
      user.currentSubscription.meta.pdfPageSupport === -1
    ) {
      setLastPdfPageLimitStatus(false);
      setLastPdfPageLimitStatus(false);
      setPdfCountLimit(false);
    } else {
      setPdfCountLimit(
        (user &&
        user.currentSubscription &&
        user.currentSubscription.meta &&
        user.currentSubscription.meta.pdfPageSupport
          ? user.currentSubscription.meta.pdfPageSupport
          : 500) < count
          ? true
          : false
      );
      setLastPdfPageLimitStatus(
        (user &&
        user.currentSubscription &&
        user.currentSubscription.meta &&
        user.currentSubscription.meta.pdfPageSupport
          ? user.currentSubscription.meta.pdfPageSupport
          : 500) < count
          ? true
          : false
      );
    }
  };

  const isValidHttpUrl = (urlString: string) => {
    let url;
    try {
      url = new URL(urlString);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  };

  const validateUrl = (value: string) => {
    // console.log("value is, ", value);
    return /^(?:(?:(?:https?|ftp|http):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
    );
  };

  const validateBookSubmission = (formData: any) => {
    const errors: any = {};

    // console.log("fileObject is, ", fileObject);

    if (
      formData.selectedRadio === "book" ||
      formData.selectedRadio === "character"
    ) {
      if (!formData.title) {
        errors.title = "Book title is required!";
      }
    }
    if (formData.selectedRadio === "webUrl" && !isSitemapSelected) {
      if (!formData.crawlUrl || formData.crawlUrl.length === 0) {
        // console.log("url is hadga, ", formData.url);

        errors.crawlUrl = "Website URL required!";
      }

      // if (formData.crawlUrl.length != 0) {
      //   let url = formData.crawlUrl.trim();

      //   if (!url.match(/^https?:\/\//i)) {
      //     url = `https://${url}`;

      //     formData.crawlUrl = url;
      //   }
      // }
      // formData.crawlUrl = formData.crawlUrl.toUpperCase();
      if (!isValidHttpUrl(formData.crawlUrl)) {
        errors.crawlUrl = "valid website URL required!";
      }
      if (!validateUrl(formData.crawlUrl)) {
        errors.crawlUrl = "valid website URL is required!";
      }
    }

    if (formData.selectedRadio === "webUrl" && isSitemapSelected) {
      if (!formData.sitemapUrl || formData.sitemapUrl.length === 0) {
        // console.log("url is hadga, ", formData.url);

        errors.sitemapUrl = "Sitemap/XML URL required!";
      }
      if (!isValidHttpUrl(formData.sitemapUrl)) {
        errors.sitemapUrl = "valid sitemap URL required!";
      }
      if (!validateUrl(formData.sitemapUrl)) {
        errors.sitemapUrl = "Enter valid Sitemap/XML URL required!";
      }
    }

    if (ytUrl && ytUrl.length > 0) {
      if (!ytUrl || ytUrl.length === 0) {
        errors.url = "YouTube URL required!";
      } else if (!matchYoutubeUrl(ytUrl)) {
        errors.url = "valid YouTube URL required!";
      }
      formData.url = ytUrl;
    }

    if (formData.selectedRadio === "youtube") {
      if (!formData.url || formData.url.length === 0) {
        errors.url = "YouTube URL required!";
      }
      if (!matchYoutubeUrl(formData.url)) {
        errors.url = "valid YouTube URL required!";
      }
    }

    if (formData.selectedRadio === "file") {
      if (!fileObject) {
        errors.file = "Please select file!";
      }
    }
    if (formData.selectedRadio === "character") {
      if (formData.charGreeting.length === 0) {
        errors.charGreeting = "Greeting is required!";
      }
      if (formData.charOtherInfo.length === 0) {
        errors.charOtherInfo = "Other Info is required!";
      }
    }
    // console.log("errors", errors);
    return errors;
  };
  const createBook = async (values: any, resetForm: any) => {
    // console.log("Create Book URl Values ", values);
    let fileExtension =
      fileObject && fileObject.name ? fileObject.name.split(".")[1] : "";
    let dataToSave = {
      title: values.title,
      image: values.image,
      authors: [values.authors],
      industryIdentifiers: [values.industryIdentifiers],
      userID: user && user.id,
      type:
        values.selectedRadio === "file"
          ? "aws"
          : values.selectedRadio === "webUrl"
          ? "url"
          : values.selectedRadio === "youtube"
          ? "youtube"
          : values.selectedRadio === "character"
          ? "character"
          : values.selectedRadio === "text"
          ? "text"
          : "book",
      fileType:
        fileObject && fileObject.type
          ? fileObject.type
          : values.selectedRadio === "webUrl"
          ? "url"
          : values.selectedRadio === "youtube"
          ? "youtube"
          : values.selectedRadio === "character"
          ? "application/text"
          : values.selectedRadio === "text"
          ? "application/text"
          : fileExtension,
      url:
        values.selectedRadio === "youtube"
          ? values.url
          : isSitemapSelected
          ? values.sitemapUrl
          : values.websiteUrl,
      fileName: fileObject ? fileObject.name : "",
      webUrl: values.webUrl,
      charGreeting: values.charGreeting,
      charOtherInfo: values.charOtherInfo,
      text: values.text,
      team: selectedTeam?._id,
      textType: values.textType,
      // model: values.model,
    };

    let createBookInput;

    if (fileObject) {
      const data = new FormData();
      data.append("files.files", fileObject);
      data.append("data", JSON.stringify(dataToSave));
      createBookInput = data;
    } else {
      createBookInput = dataToSave;
    }

    myAxios
      .post(
        process.env.REACT_APP_SERVER_URL + "/books/create-book",
        createBookInput
      )
      .then((response) => {
        // console.log("response is , ", response.data);
        if (response.data && response.data.success) {
          const newBooks = response.data.data.books.filter(
            (item2: Book) =>
              !books.some((item1: Book) => item1._id === item2._id)
          )[0];
          setBooks(response.data.data.books);
          setTotalPage(response.data.data.count);
          setNoOfTeamBooks(
            response.data.data.totalTeamBooks.length > 0
              ? response.data.data.totalTeamBooks.length
              : 0
          );
          setTotalBooksCount(response.data.data.count);
          setResetPagination(true);
          resetForm();

          if (fileObject) {
            updateUserSubscriptionIndexCount(
              (user?.currentSubscription?.indexCharacterUsedTotal
                ? user?.currentSubscription?.indexCharacterUsedTotal
                : 0) + characterCount
            );
          }
          if (dataToSave && dataToSave.type === "youtube") {
            updateUserSubscriptionIndexCount(
              (user?.currentSubscription?.indexCharacterUsedTotal
                ? user?.currentSubscription?.indexCharacterUsedTotal
                : 0) + ytUrlCharacterCount
            );
          }

          if (dataToSave && dataToSave.type === "text") {
            updateUserSubscriptionIndexCount(
              (user?.currentSubscription?.indexCharacterUsedTotal
                ? user?.currentSubscription?.indexCharacterUsedTotal
                : 0) + totalTextCharacterCount
            );
          }

          setFileObject(undefined);
          if (isTeamOwner) {
            NotificationManager.success(
              response.data.message
                ? response.data.message
                : "Book successfully created!"
            );
          } else {
            NotificationManager.info(
              "Chatbot is successfully created, please ask owner to access this chatbot!"
            );
          }
          setShowLoader(false);
          if (newBooks) {
            trackEvent({
              email: getUsersEmail(user),
              eventCategory: EventCategory.CHATBOT,
              event: ChatBotEvents.USER_CREATED_NEW_CHATBOT,
              subCategory: SubCategory.EVENT,
              source: Source.CHAT,
              chatbotType: values.selectedRadio,
              rawData: values,
              bookId: newBooks._id,
            });
          }
          if (dataToSave.type !== "book") {
            if (!isTestUser(user?.email))
              Mixpanel.track(
                `Custom bot created by Loggedin user [${values.selectedRadio}]`,
                {
                  email: user?.email,
                  bookTitle: dataToSave.title,
                  bookAuthors: dataToSave.authors,
                  url: values.url,
                  fileName: fileObject ? fileObject.name : "",
                  type: values.selectedRadio,
                }
              );
          }
          if (
            response.data.data.count >=
            user?.currentSubscription?.meta?.noOfBooks!
          ) {
            trackEvent({
              email: getUsersEmail(user),
              eventCategory: EventCategory.CHATBOT,
              event: ChatBotEvents.USER_GOES_AVOVE_CHATBOT_LIMIT,
              subCategory: SubCategory.EVENT,
              source: Source.CHAT,
              rawData: {
                planBots: user?.currentSubscription?.meta?.noOfBooks,
                totalCreatedBots: response.data.data.count,
              },
            });
            if (!isTestUser(user?.email))
              Mixpanel.track("Chatbot limit reached", {
                email: user?.email,
                planBots: user?.currentSubscription?.meta?.noOfBooks,
                totalCreatedBots: response.data.data.count,
              });
          }
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })
      .catch((error) => {
        // handle error
        resetForm();
        setShowLoader(false);
        setFileObject(undefined);
        NotificationManager.error(parseErrorResponse(error));
      });
    if (!isTestUser(user?.email))
      _cio.track(USER_ADD_A_BOOK, {
        name: "User add a book using create book form",
      });
  };

  // console.log("popuopurl: ",popupUrlContent)

  const createUrlBook = async (createBookInput: any, resetForm: any) => {
    setShowLoader(true);
    myAxios
      .post(
        process.env.REACT_APP_SERVER_URL + "/books/create-book",
        createBookInput
      )
      .then((response) => {
        if (response.data && response.data.success) {
          const newBooks = response.data.data.books.filter(
            (item2: Book) =>
              !books.some((item1: Book) => item1._id === item2._id)
          )[0];

          if (newBooks) {
            trackEvent({
              email: getUsersEmail(user),
              eventCategory: EventCategory.CHATBOT,
              event: ChatBotEvents.USER_CREATED_NEW_CHATBOT,
              subCategory: SubCategory.EVENT,
              source: Source.CHAT,
              chatbotType: "url",
              bookId: newBooks._id,
              rawData: createBookInput,
            });
          }
          if (!isTestUser(user?.email))
            Mixpanel.track("Custom bot created by Loggedin user [URL]", {
              email: createBookInput.email,
              bookTitle: createBookInput.title,
              bookAuthors: createBookInput.authors,
              url: createBookInput.url,
              type: "url",
            });

          if (
            response.data.data.count >=
            user?.currentSubscription?.meta?.noOfBooks!
          ) {
            trackEvent({
              email: getUsersEmail(user),
              eventCategory: EventCategory.CHATBOT,
              event: ChatBotEvents.USER_GOES_AVOVE_CHATBOT_LIMIT,
              subCategory: SubCategory.EVENT,
              source: Source.CHAT,
              rawData: {
                planBots: user?.currentSubscription?.meta?.noOfBooks,
                totalCreatedBots: response.data.data.count,
              },
            });
            if (!isTestUser(user?.email))
              Mixpanel.track("Chatbot limit reached", {
                email: user?.email,
                planBots: user?.currentSubscription?.meta?.noOfBooks,
                totalCreatedBots: response.data.data.count,
              });
          }
          setBooks(response.data.data.books);
          console.log(
            "response.data.data.totalTeamBooks is, ",
            response.data.data.totalTeamBooks
          );
          setNoOfTeamBooks(
            response.data.data.totalTeamBooks.length > 0
              ? response.data.data.totalTeamBooks.length
              : 0
          );
          setTotalPage(response.data.data.count);
          setTotalBooksCount(response.data.data.count);
          setResetPagination(true);
          //  resetForm();
          setFileObject(undefined);
          if (isTeamOwner) {
            NotificationManager.success(
              response.data.message
                ? response.data.message
                : "Chatbot successfully created!"
            );
          } else {
            NotificationManager.info(
              "Chatbot is successfully created, please ask owner to access this chatbot!"
            );
          }
          setShowLoader(false);
        } else {
          setShowLoader(false);
          NotificationManager.error(
            response.data.message
              ? response.data.message
              : "Unable to process your request, please try later"
          );
        }
      })
      .catch((error) => {
        // handle error
        resetForm();
        setShowLoader(false);
        setFileObject(undefined);
        NotificationManager.error(parseErrorResponse(error));
      });
    if (!isTestUser(user?.email))
      _cio.track(USER_ADD_A_BOOK, {
        name: "User add a chatbot using create book form",
      });
  };

  const onLoadSuccess = async (pdf: any) => {
    const numPages = pdf.numPages;

    setNumPages(numPages);
    checkPdfPageLimit(numPages);

    let extractedText = "";
    for (let i = 1; i <= numPages; i++) {
      const page = await pdf.getPage(i);
      const textContent = await page.getTextContent();
      const pageText = textContent.items.map((item: any) => item.str).join(" ");
      extractedText += pageText;
    }
    setCharacterCount(extractedText.length);
  };
  useEffect(() => {
    const indexingCharacterLimitCount = Math.max(
      (user?.currentSubscription?.meta?.indexCharacterSupport
        ? user?.currentSubscription?.meta?.indexCharacterSupport
        : 0) -
        (user?.currentSubscription?.indexCharacterUsedTotal
          ? user?.currentSubscription?.indexCharacterUsedTotal
          : 0),
      0
    );
    if (characterCount > indexingCharacterLimitCount) {
      setDisablePdfIndexLimitState(true);
    } else {
      setDisablePdfIndexLimitState(false);
    }
    // eslint-disable-next-line
  }, [setCharacterCount, characterCount]);

  const handleOnSearch = (string: string) => {
    // console.log("string is, ", string);
    setQuery(string);
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    myAxios
      .post(process.env.REACT_APP_SERVER_URL + "/loaddata", {
        query: string,
        currentPage: 0,
        maxResult: 20,
      })
      .then((response) => {
        // handle success
        if (response.data.success) {
          if (response.data.data.items && response.data.data.items.length > 0) {
            // console.log("response.data.data is, ", response.data.data.items);
            let filteredOptions = response.data.data.items.filter(
              (dt: any) =>
                dt &&
                dt.volumeInfo &&
                dt.volumeInfo.title &&
                dt.volumeInfo.title.length > 0 &&
                dt.volumeInfo.industryIdentifiers &&
                dt.volumeInfo.industryIdentifiers.length > 0 &&
                dt.volumeInfo.authors &&
                dt.volumeInfo.authors.length > 0
            );
            let finalOptions = filteredOptions.map((option: any) => {
              return {
                ...option,
                name: option.volumeInfo.title,
              };
            });
            setOptions(finalOptions);
          }
        } else {
          // NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        NotificationManager.error(parseErrorResponse(error));
      });
  };
  // console.log("options are, ", options);

  const onInputChange = (event: any) => {
    // console.log("called, ", event);
    if (event.query) {
      handleOnSearch(event.query);
    } else {
      setOptions([]);
    }
  };
  const [showYTChar, setShowYTChar] = useState<boolean>(false);
  const getYTIndex = () => {
    setShowLoader(true);

    if (ytUrl && ytUrl.length > 0) {
      if (!matchYoutubeUrl(ytUrl)) {
        NotificationManager.error("valid YouTube URL required!");
        setShowLoader(false);
      } else {
        myAxios
          .post(process.env.REACT_APP_SERVER_URL + "/books/yt-text-count", {
            ytUrl: ytUrl,
          })
          .then((response) => {
            setShowLoader(false);
            if (response.data && response.data.success) {
              setYtUrlCharacterCount(response.data.data.textCount);
              setShowYTChar(true);
            } else {
              setShowLoader(false);
              NotificationManager.error(
                response.data.message
                  ? response.data.message
                  : "Unable to process your request, please try later"
              );
            }
          })
          .catch((error) => {
            setShowLoader(false);
            NotificationManager.error(parseErrorResponse(error));
          });
      }
    }
  };
  const handleInputChange = (e: any) => {
    const newValue = e.target.value;
    setYTUrl(newValue);
    setShowYTChar(false);
    // You can perform additional logic here if needed
  };
  useEffect(() => {
    const indexingCharacterLimitCount = Math.max(
      (user?.currentSubscription?.meta?.indexCharacterSupport
        ? user?.currentSubscription?.meta?.indexCharacterSupport
        : 0) -
        (user?.currentSubscription?.indexCharacterUsedTotal
          ? user?.currentSubscription?.indexCharacterUsedTotal
          : 0),
      0
    );
    if (ytUrlCharacterCount > indexingCharacterLimitCount) {
      setDisableYTUrlIndexLimitState(true);
    } else {
      setDisableYTUrlIndexLimitState(false);
    }
    // eslint-disable-next-line
  }, [setYtUrlCharacterCount, ytUrlCharacterCount]);

  const getData = async () => {
    const browser = await getDeviceInfoForTrackEvent();
    setGuestUserInfo(browser);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const indexingCharacterLimitCount = Math.max(
      (user?.currentSubscription?.meta?.indexCharacterSupport
        ? user?.currentSubscription?.meta?.indexCharacterSupport
        : 0) -
        (user?.currentSubscription?.indexCharacterUsedTotal
          ? user?.currentSubscription?.indexCharacterUsedTotal
          : 0),
      0
    );
    if (totalTextCharacterCount > indexingCharacterLimitCount) {
      setDisableTextIndexLimitState(true);
    } else {
      setDisableTextIndexLimitState(false);
    }
  }, [totalTextCharacterCount, setTotalTextCharacterCount]);

  // logic to handle the URL based on the selected rado button by the user and hit the refresh

  useEffect(() => {
    var url = location.pathname.split("/").pop();
    if (url === "my-chatbots" || url === "create-pdf-chatbot") {
      setSelectedRadioOption("file");
    }
    if (url === "create-url-chatbot") {
      setSelectedRadioOption("webUrl");
    }
    if (url === "create-text-chatbot") {
      setSelectedRadioOption("text");
    }
    if (url === "create-character-chatbot") {
      setSelectedRadioOption("character");
    }
  }, [location, selectedRadioOption]);

  return (
    <>
      <div style={{ display: "none" }}>
        <Document
          file={fileObject && fileObject}
          onLoadSuccess={onLoadSuccess}
        ></Document>
      </div>
      <Formik
        initialValues={{
          title: "",
          image: "",
          authors: [],
          industryIdentifiers: [],
          file: "",
          selectedRadio: selectedRadioOption,
          url: ytUrl,
          webUrl: "",
          sitemapUrl: "",
          crawlUrl: "",
          charGreeting: "",
          charOtherInfo: "",
          text: "",
          textType: "textWrite",
        }}
        enableReinitialize
        validate={validateBookSubmission}
        onSubmit={(values, { resetForm }: any) => {
          setShowLoader(true);
          if (values.selectedRadio === "webUrl") {
            myAxios
              .post(
                process.env.REACT_APP_SERVER_URL + "/books/urls",
                {
                  url: isSitemapSelected ? values.sitemapUrl : values.crawlUrl,
                },
                { timeout: 600000 }
              )
              .then((response) => {
                setShowLoader(false);
                // console.log("response is , ", response.data);
                if (response.data && response.data.success) {
                  setShowLoader(false);
                  setUrlList(response.data.data);
                  let dataToSave = {
                    title: values.title,
                    userID: user && user.id,
                    type: "url",
                    fileType: "url",
                    url: values.url,
                    fileName: "",
                    webUrl: values.webUrl,
                    team: selectedTeam?._id,
                    // model: values.model,
                  };
                  setPopupUrlContent({
                    type: isSitemapSelected ? "xml" : "web",
                    url: isSitemapSelected
                      ? values.sitemapUrl
                      : values.crawlUrl,
                  });
                  setCreateBookInput(dataToSave);
                  setResetForm(resetForm);
                  setShowConfirmationBox(true);
                  if (response.data.data.length <= 0) {
                    NotificationManager.info(
                      "No URLs found on provided location or it provides URLs in the format that is not yet supported."
                    );
                  }
                } else {
                  setShowLoader(false);
                  NotificationManager.error(
                    response.data.message
                      ? response.data.message
                      : "Unable to process your request, please try later"
                  );
                }
              })
              .catch((error) => {
                // handle error
                resetForm();
                setShowLoader(false);
                setFileObject(undefined);
                NotificationManager.error(parseErrorResponse(error));
              });
          } else createBook(values, resetForm);
        }}
      >
        {({ values, errors, touched, setFieldValue }: any) => (
          <BookFetchForm className={CREATE_BOOK}>
            <FieldSection>
              <div id="my-radio-group"></div>
              <div role="group" aria-labelledby="my-radio-group">
                <label style={{ display: "flex", cursor: "pointer" }}>
                  <FieldS
                    type="radio"
                    name="selectedRadio"
                    value="file"
                    onChange={() => {
                      navigate("/my-chatbots/create-pdf-chatbot");
                      setFieldValue("selectedRadio", "file");
                    }}
                  />
                  <FormLabelSpan>PDF File</FormLabelSpan>
                </label>
                <MainLabel>
                  <FieldS
                    type="radio"
                    name="selectedRadio"
                    value="webUrl"
                    onChange={() => {
                      navigate("/my-chatbots/create-url-chatbot");
                      setFieldValue("selectedRadio", "webUrl");
                    }}
                  />
                  <FormLabelSpan>Website URL</FormLabelSpan>
                </MainLabel>
                {/* <MainLabel>
                  <FieldS type="radio" name="selectedRadio" value="youtube" />
                  <FormLabelSpan>YouTube URL</FormLabelSpan>
                </MainLabel> */}
                {process.env.REACT_APP_ENV !== "production" && (
                  <MainLabel>
                    <FieldS
                      type="radio"
                      name="selectedRadio"
                      value="character"
                      onChange={() => {
                        navigate("/my-chatbots/create-character-chatbot");
                        setFieldValue("selectedRadio", "character");
                      }}
                    />
                    <FormLabelSpan>Character</FormLabelSpan>
                  </MainLabel>
                )}
                <MainLabel>
                  <FieldS
                    type="radio"
                    name="selectedRadio"
                    value="text"
                    onChange={() => {
                      navigate("/my-chatbots/create-text-chatbot");
                      setFieldValue("selectedRadio", "text");
                    }}
                  />
                  <FormLabelSpan>Text</FormLabelSpan>
                </MainLabel>
                {/* <MainLabel>
                  <FieldS type="radio" name="selectedRadio" value="book" />
                  <FormLabelSpan>Book</FormLabelSpan>
                </MainLabel> */}
              </div>
            </FieldSection>
            {values.selectedRadio === "book" ? (
              <></>
            ) : // <>
            //   <FieldSection>
            //     <FormLabel>
            //       Search for books using ISBN-10 or ISBN-13 number, author, or
            //       title
            //     </FormLabel>
            //   </FieldSection>
            //   <FieldSection style={{ marginBottom: "0" }}>
            //     <FormLabel>Book Title</FormLabel>
            //   </FieldSection>
            //   <AutoComplete
            //     className="autocompleteFieldAfterLogin"
            //     value={selectedOption}
            //     field="name"
            //     suggestions={options}
            //     completeMethod={onInputChange}
            //     onChange={(e) => {
            //       setSelectedOption(e.value);
            //       if (
            //         e.value &&
            //         e.value != null &&
            //         typeof e.value === "object"
            //       ) {
            //         const AUTHORS =
            //           e.value.volumeInfo.authors &&
            //           e.value.volumeInfo.authors
            //             .map((author: any) => author)
            //             .join(", ");
            //         const ISBN = e.value.volumeInfo.industryIdentifiers
            //           .map((isbn: any) => isbn.identifier)
            //           .join(", ");
            //         setFieldValue("title", e.value.volumeInfo.title);
            //         setFieldValue("industryIdentifiers", ISBN);
            //         setFieldValue("authors", AUTHORS);
            //         setFieldValue(
            //           "image",
            //           e.value.volumeInfo.imageLinks.thumbnail
            //         );
            //       } else {
            //         setFieldValue("title", "");
            //         setFieldValue("industryIdentifiers", "");
            //         setFieldValue("authors", "");
            //         setFieldValue("image", "");
            //       }
            //     }}
            //   />
            //   {errors.title && touched.title && (
            //     <FormError className="formError">{errors.title}</FormError>
            //   )}
            // </>
            values.selectedRadio === "file" ? (
              <FieldSection>
                <FormLabel style={{ fontWeight: "500" }}>
                  Custom File Title
                </FormLabel>
                <FormInput name="title" />
                {errors.title && touched.title && (
                  <FormError className="formError">{errors.title}</FormError>
                )}
              </FieldSection>
            ) : values.selectedRadio === "character" ? (
              <FieldSection>
                <FormLabel style={{ fontWeight: "500" }}>Name</FormLabel>
                <FormInput
                  name="title"
                  placeholder="Name your person / character"
                />
                {errors.title && touched.title && (
                  <FormError className="formError">{errors.title}</FormError>
                )}
              </FieldSection>
            ) : values.selectedRadio === "youtube" ? (
              <FieldSection>
                <FormLabel>YouTube URL Title</FormLabel>
                <FormInput name="title" />
                {errors.title && touched.title && (
                  <FormError className="formError">{errors.title}</FormError>
                )}
              </FieldSection>
            ) : values.selectedRadio === "text" ? (
              <FieldSection>
                <FormLabel>Text Title</FormLabel>
                <FormInput name="title" />
                {errors.title && touched.title && (
                  <FormError className="formError">{errors.title}</FormError>
                )}
              </FieldSection>
            ) : (
              <FieldSection>
                <FormLabel>Website URL Title</FormLabel>
                <FormInput name="title" />
                {errors.title && touched.title && (
                  <FormError className="formError">{errors.title}</FormError>
                )}
              </FieldSection>
            )}
            {/* {values.selectedRadio === "book" && (
              <FieldSection>
                <FormLabel>Author</FormLabel>
                <FormInput name="authors" />
                {errors.authors && touched.authors && (
                  <FormError className="formError">{errors.authors}</FormError>
                )}
              </FieldSection>
            )} */}
            {values.selectedRadio === "file" && (
              <>
                {lastPdfPageLimitStatus === true
                  ? setPdfCountLimit(true)
                  : setPdfCountLimit(false)}
                <FieldSection>
                  <Col>
                    <FormLabel style={{ fontWeight: "500" }}>
                      Custom Document/File
                    </FormLabel>
                  </Col>
                  <ColPDFMainSubSection>
                    <FormLabelDocInput htmlFor="docFile">
                      Select File
                    </FormLabelDocInput>
                    <FormInput
                      type="file"
                      id="docFile"
                      name="file"
                      accept=".pdf"
                      hidden
                      placeholder="select custom document/file"
                      onChange={(e: any) => {
                        setFileObject(e.target.files[0]);
                        errors.file = "";
                      }}
                    />
                    {/* , .txt, .mp3, .html, .ogg, .wav, .flac, .mp4, .epub, .md, .mdx */}

                    <ColForPDFSelection>
                      <PDFNameFormLabel>
                        {fileObject ? fileObject.name : "No file selected"}
                      </PDFNameFormLabel>
                      {errors.file && touched.file && (
                        <FormError className="formError">
                          {errors.file}
                        </FormError>
                      )}
                      {fileObject && (
                        <FormLabel
                          style={{
                            padding: "0",
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                        >
                          <SelectedCount
                            style={{
                              color: disablePdfIndexLimit ? "red" : "#33303c",
                            }}
                          >
                            {characterCount} {" / "}
                            {Math.max(
                              (user?.currentSubscription?.meta
                                ?.indexCharacterSupport
                                ? user?.currentSubscription?.meta
                                    ?.indexCharacterSupport
                                : 0) -
                                (user?.currentSubscription
                                  ?.indexCharacterUsedTotal
                                  ? user?.currentSubscription
                                      ?.indexCharacterUsedTotal
                                  : 0),
                              0
                            )}{" "}
                            {disablePdfIndexLimit
                              ? "Characters limit exceeds"
                              : "Characters"}
                          </SelectedCount>
                        </FormLabel>
                      )}
                    </ColForPDFSelection>
                  </ColPDFMainSubSection>
                </FieldSection>
              </>
            )}
            {/* {values.selectedRadio === "url" && (
              <>
                <FieldSection>
                  <FormLabel>Url</FormLabel>
                  <FormInput name="url" placeholder="Enter url here" />
                  {errors.url && touched.url && (
                    <FormError className="formError">{errors.url}</FormError>
                  )}
                </FieldSection>
              </>
            )} */}
            {values.selectedRadio === "webUrl" && (
              <>
                {pdfCountLimit && setPdfCountLimit(false)}
                <label style={{ marginBottom: "5px" }}>
                  <Field
                    type="radio"
                    name="urlOption"
                    value="websiteUrl"
                    checked={!isSitemapSelected}
                    onChange={() => setIsSitemapSelected(false)}
                  />
                  <FormLabelSpan style={{ marginLeft: "8px" }}>
                    Website URL
                  </FormLabelSpan>
                </label>
                <FieldSection>
                  <FormInput name="crawlUrl" disabled={isSitemapSelected} />
                  {errors.crawlUrl && touched.crawlUrl && (
                    <FormError className="formError">
                      {errors.crawlUrl}
                    </FormError>
                  )}
                </FieldSection>
                <img src={OrIconImage} alt="or" width={"100%"} />
                <label style={{ marginBottom: "5px" }}>
                  <Field
                    type="radio"
                    name="urlOption"
                    value="sitemapOrXml"
                    checked={isSitemapSelected}
                    onChange={() => setIsSitemapSelected(true)}
                  />
                  <FormLabelSpan style={{ marginLeft: "8px" }}>
                    Sitemap/XML URL
                  </FormLabelSpan>
                </label>
                <FieldSection>
                  <FormInput name="sitemapUrl" disabled={!isSitemapSelected} />
                  {errors.sitemapUrl && touched.sitemapUrl && (
                    <FormError className="formError">
                      {errors.sitemapUrl}
                    </FormError>
                  )}
                </FieldSection>
              </>
            )}
            {values.selectedRadio === "youtube" && (
              <>
                {pdfCountLimit && setPdfCountLimit(false)}
                <FieldSection>
                  <FormLabel>YouTube URL</FormLabel>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormInput
                      style={{ position: "relative" }}
                      name="url"
                      value={ytUrl}
                      onChange={handleInputChange}
                    />
                    <SubmitYoutubeIcon
                      src={FRMAEIMAGE}
                      alt="or"
                      onClick={() => {
                        getYTIndex();
                        errors.url = "";
                      }}
                    />
                  </div>
                  {showYTChar && (
                    <FormLabel
                      style={{
                        padding: "0",
                        paddingTop: ".3rem",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      <SelectedCount
                        style={{
                          color: disableYTUrlIndexLimit ? "red" : "#33303c",
                        }}
                      >
                        {ytUrlCharacterCount} {" / "}
                        {Math.max(
                          (user?.currentSubscription?.meta
                            ?.indexCharacterSupport
                            ? user?.currentSubscription?.meta
                                ?.indexCharacterSupport
                            : 0) -
                            (user?.currentSubscription?.indexCharacterUsedTotal
                              ? user?.currentSubscription
                                  ?.indexCharacterUsedTotal
                              : 0),
                          0
                        )}{" "}
                        {disableYTUrlIndexLimit
                          ? "Characters limit exceeds"
                          : "Characters"}
                      </SelectedCount>{" "}
                    </FormLabel>
                  )}
                  {/* <FormInput name="url" placeholder="Enter youtube url here" /> */}
                  {errors.url && touched.url && (
                    <FormError className="formError">{errors.url}</FormError>
                  )}
                </FieldSection>
              </>
            )}
            {values.selectedRadio === "text" && (
              <>
                <div style={{ margin: "0.5rem 0" }}>
                  <div style={{ marginRight: "1rem" }}>
                    <Field
                      type="radio"
                      name="textType"
                      value="textWrite"
                      onClick={() => setTotalTextCharacterCount(0)}
                    />{" "}
                    Write Text
                  </div>
                  <div>
                    <Field
                      type="radio"
                      name="textType"
                      value="textFile"
                      onClick={() => setTotalTextCharacterCount(0)}
                    />{" "}
                    Upload Text File
                  </div>
                </div>
                {values.textType === "textWrite" ? (
                  <>
                    <FormLabel>Text</FormLabel>
                    <FieldSection>
                      <FormInputTextArea
                        name="text"
                        placeholder="Type text here"
                        maxLength={100000}
                        onChange={(e) => {
                          setTotalTextCharacterCount(e.target.value.length);
                          setFieldValue("text", e.target.value);
                        }}
                      />
                      <TextAreaLimitSpan
                        style={{
                          background:
                            totalTextCharacterCount === 100000
                              ? "red"
                              : "#1585b5",
                        }}
                      >
                        {totalTextCharacterCount === 0
                          ? 0
                          : totalTextCharacterCount}
                        /100000
                      </TextAreaLimitSpan>
                    </FieldSection>
                    {errors.text && touched.text && (
                      <FormError className="formError">{errors.text}</FormError>
                    )}
                  </>
                ) : (
                  <FieldSection>
                    <UploadComponent
                      setFieldValue={setFieldValue}
                      setTotalTextCharacterCount={setTotalTextCharacterCount}
                      fileObject={fileObject}
                      setFileObject={setFileObject}
                    />
                  </FieldSection>
                )}
                <FormLabel
                  style={{
                    padding: "0",
                    paddingTop: ".3rem",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  <SelectedCount
                    style={{
                      color: disableTextIndexLimit ? "red" : "#33303c",
                    }}
                  >
                    {totalTextCharacterCount} {" / "}
                    {Math.max(
                      (user?.currentSubscription?.meta?.indexCharacterSupport
                        ? user?.currentSubscription?.meta?.indexCharacterSupport
                        : 0) -
                        (user?.currentSubscription?.indexCharacterUsedTotal
                          ? user?.currentSubscription?.indexCharacterUsedTotal
                          : 0),
                      0
                    )}{" "}
                    {disableTextIndexLimit
                      ? "Characters limit exceeds"
                      : "Characters"}
                  </SelectedCount>{" "}
                </FormLabel>
              </>
            )}
            {values.selectedRadio === "book" && (
              <>
                {pdfCountLimit && setPdfCountLimit(false)}

                <FieldSection>
                  <FormLabel>Book ISBN Number</FormLabel>
                  <FormInput name="industryIdentifiers" />
                  {errors.industryIdentifiers &&
                    touched.industryIdentifiers && (
                      <FormError className="formError">
                        {errors.industryIdentifiers}
                      </FormError>
                    )}
                </FieldSection>
              </>
            )}
            {values.selectedRadio === "character" && (
              <>
                {pdfCountLimit && setPdfCountLimit(false)}
                <FormLabel>Greeting</FormLabel>
                <FieldSection>
                  <FormInputTextArea
                    name="charGreeting"
                    placeholder={`What would ${
                      values.title ? values.title : "Character"
                    } say to introduce themselves?`}
                    onChange={(e) => {
                      setFieldValue("charGreeting", e.target.value);
                    }}
                  />
                </FieldSection>
                {errors.charGreeting && touched.charGreeting && (
                  <FormError className="formError">
                    {errors.charGreeting}
                  </FormError>
                )}
                <FormLabel>Other information about character</FormLabel>
                <FieldSection>
                  <FormInputTextArea
                    name="charOtherInfo"
                    placeholder={`Tell us anything else about ${
                      values.title ? values.title : "this character"
                    } `}
                    onChange={(e) => {
                      setFieldValue("charOtherInfo", e.target.value);
                    }}
                  />
                </FieldSection>
                {errors.charOtherInfo && touched.charOtherInfo && (
                  <FormError className="formError">
                    {errors.charOtherInfo}
                  </FormError>
                )}
              </>
            )}
            <Row>
              {values.selectedRadio === "book" && (
                <Col>
                  <SavePDFButton type="submit">Create Chatbot</SavePDFButton>
                </Col>
              )}
              {values.selectedRadio === "character" && (
                <Col>
                  <SavePDFButton
                    type="submit"
                    // disabled={values.characterText.length > 0 ? false : true}
                  >
                    Create Chatbot
                  </SavePDFButton>
                </Col>
              )}
              {values.selectedRadio === "file" &&
                !pdfCountLimit &&
                (user?.currentSubscription ? (
                  user?.currentSubscription.meta?.allowPDFIndex &&
                  !disablePdfIndexLimit ? (
                    <Col>
                      <SavePDFButton
                        type="submit"
                        disabled={characterCount ? false : true}
                      >
                        Create Chatbot
                      </SavePDFButton>
                    </Col>
                  ) : (
                    <Col style={{ cursor: "not-allowed" }}>
                      <SavePDFButton type="button" disabled>
                        Create Chatbot
                      </SavePDFButton>
                      <UpgradePDfSpan
                        onClick={(e) => {
                          if (disablePdfIndexLimit) {
                            setPlanHighliter({
                              isBookHighliter: false,
                              bookLimit: 0,
                              isDeleteBookHighliter: false,
                              isFlashcardHighliter: false,
                              isCharacterLimit: false,
                              pdfLimit: 0,
                              isIndexCharacterLimit: true,
                            });
                          } else {
                            setPlanHighliter({
                              isBookHighliter: false,
                              bookLimit: 0,
                              isDeleteBookHighliter: false,
                              isFlashcardHighliter: false,
                              isCharacterLimit: false,
                              pdfLimit: 0,
                              isDeleteAccount: false,
                              isLeadLoginSettings: false,
                              isAllowYTIndex: false,
                              isAllowPDFIndex: true,
                            });
                          }
                          navigate("/subscription");
                        }}
                      >
                        Upgrade Plan
                      </UpgradePDfSpan>
                    </Col>
                  )
                ) : (
                  <Col style={{ cursor: "not-allowed" }}>
                    <SavePDFButton type="button" disabled>
                      Create Chatbot
                    </SavePDFButton>
                    <UpgradePDfSpan
                      onClick={(e) => {
                        setPlanHighliter({
                          isBookHighliter: false,
                          bookLimit: 0,
                          isDeleteBookHighliter: false,
                          isFlashcardHighliter: false,
                          isCharacterLimit: false,
                          pdfLimit: 0,
                          isDeleteAccount: false,
                          isLeadLoginSettings: false,
                          isAllowYTIndex: false,
                          isAllowPDFIndex: true,
                        });
                        navigate("/subscription");
                      }}
                    >
                      Purchase Plan
                    </UpgradePDfSpan>
                  </Col>
                ))}

              {values.selectedRadio === "webUrl" &&
                (user?.currentSubscription ? (
                  user?.currentSubscription.meta?.allowWebsiteIndex ? (
                    <Col>
                      <SavePDFButton type="submit">FETCH LINKS</SavePDFButton>
                    </Col>
                  ) : (
                    <Col style={{ cursor: "not-allowed" }}>
                      <SavePDFButton type="button" disabled>
                        FETCH LINKS
                      </SavePDFButton>
                      <UpgradePDfSpan
                        onClick={(e) => {
                          setPlanHighliter({
                            isBookHighliter: false,
                            bookLimit: 0,
                            isDeleteBookHighliter: false,
                            isFlashcardHighliter: false,
                            isCharacterLimit: false,
                            pdfLimit: 0,
                            isDeleteAccount: false,
                            isLeadLoginSettings: false,
                            isAllowYTIndex: false,
                            isAllowPDFIndex: false,
                            isAllowWebsiteIndex: true,
                          });
                          navigate("/subscription");
                        }}
                      >
                        Upgrade Plan
                      </UpgradePDfSpan>
                      {/* <PlanSpan>Your plan</PlanSpan> */}
                    </Col>
                  )
                ) : (
                  <Col style={{ cursor: "not-allowed" }}>
                    <SavePDFButton type="button" disabled>
                      FETCH LINKS
                    </SavePDFButton>
                    <UpgradePDfSpan
                      onClick={(e) => {
                        setPlanHighliter({
                          isBookHighliter: false,
                          bookLimit: 0,
                          isDeleteBookHighliter: false,
                          isFlashcardHighliter: false,
                          isCharacterLimit: false,
                          pdfLimit: 0,
                          isDeleteAccount: false,
                          isLeadLoginSettings: false,
                          isAllowYTIndex: false,
                          isAllowPDFIndex: false,
                          isAllowWebsiteIndex: true,
                        });
                        navigate("/subscription");
                      }}
                    >
                      Purchase Plan
                    </UpgradePDfSpan>
                  </Col>
                ))}

              {values.selectedRadio === "youtube" &&
                (user?.currentSubscription ? (
                  user?.currentSubscription.meta?.allowYTIndex &&
                  !disableYTUrlIndexLimit ? (
                    <Col>
                      <SavePDFButton
                        type="submit"
                        disabled={showYTChar ? false : true}
                      >
                        Create Chatbot
                      </SavePDFButton>
                    </Col>
                  ) : (
                    <Col style={{ cursor: "not-allowed" }}>
                      <SavePDFButton type="button" disabled>
                        Create Chatbot
                      </SavePDFButton>
                      <UpgradePDfSpan
                        onClick={(e) => {
                          if (disableYTUrlIndexLimit) {
                            setPlanHighliter({
                              isBookHighliter: false,
                              bookLimit: 0,
                              isDeleteBookHighliter: false,
                              isFlashcardHighliter: false,
                              isCharacterLimit: false,
                              pdfLimit: 0,
                              isIndexCharacterLimit: true,
                            });
                          } else {
                            setPlanHighliter({
                              isBookHighliter: false,
                              bookLimit: 0,
                              isDeleteBookHighliter: false,
                              isFlashcardHighliter: false,
                              isCharacterLimit: false,
                              pdfLimit: 0,
                              isDeleteAccount: false,
                              isLeadLoginSettings: false,
                              isAllowYTIndex: true,
                            });
                          }
                          navigate("/subscription");
                        }}
                      >
                        Upgrade Plan
                      </UpgradePDfSpan>
                      {/* <PlanSpan>Your plan</PlanSpan> */}
                    </Col>
                  )
                ) : (
                  <Col style={{ cursor: "not-allowed" }}>
                    <SavePDFButton type="button" disabled>
                      Create Chatbot
                    </SavePDFButton>
                    <UpgradePDfSpan
                      onClick={(e) => {
                        setPlanHighliter({
                          isBookHighliter: false,
                          bookLimit: 0,
                          isDeleteBookHighliter: false,
                          isFlashcardHighliter: false,
                          isCharacterLimit: false,
                          pdfLimit: 0,
                          isDeleteAccount: false,
                          isLeadLoginSettings: false,
                          isAllowYTIndex: true,
                        });
                        navigate("/subscription");
                      }}
                    >
                      Purchase Plan
                    </UpgradePDfSpan>
                  </Col>
                ))}

              {values.selectedRadio === "text" &&
                (user?.currentSubscription ? (
                  !disableTextIndexLimit ? (
                    <Col>
                      <SavePDFButton
                        type="submit"
                        disabled={disableTextIndexLimit}
                      >
                        Create Chatbot
                      </SavePDFButton>
                    </Col>
                  ) : (
                    <Col style={{ cursor: "not-allowed" }}>
                      <SavePDFButton type="button" disabled>
                        Create Chatbot
                      </SavePDFButton>
                      <UpgradePDfSpan
                        onClick={(e) => {
                          if (disableYTUrlIndexLimit) {
                            setPlanHighliter({
                              isBookHighliter: false,
                              bookLimit: 0,
                              isDeleteBookHighliter: false,
                              isFlashcardHighliter: false,
                              isCharacterLimit: false,
                              pdfLimit: 0,
                              isIndexCharacterLimit: true,
                            });
                          } else {
                            setPlanHighliter({
                              isBookHighliter: false,
                              bookLimit: 0,
                              isDeleteBookHighliter: false,
                              isFlashcardHighliter: false,
                              isCharacterLimit: false,
                              pdfLimit: 0,
                              isDeleteAccount: false,
                              isLeadLoginSettings: false,
                              isAllowYTIndex: true,
                            });
                          }
                          navigate("/subscription");
                        }}
                      >
                        Upgrade Plan
                      </UpgradePDfSpan>
                      {/* <PlanSpan>Your plan</PlanSpan> */}
                    </Col>
                  )
                ) : (
                  <Col style={{ cursor: "not-allowed" }}>
                    <SavePDFButton type="button" disabled>
                      Create Chatbot
                    </SavePDFButton>
                    <UpgradePDfSpan
                      onClick={(e) => {
                        setPlanHighliter({
                          isBookHighliter: false,
                          bookLimit: 0,
                          isDeleteBookHighliter: false,
                          isFlashcardHighliter: false,
                          isCharacterLimit: false,
                          pdfLimit: 0,
                          isDeleteAccount: false,
                          isLeadLoginSettings: false,
                          isAllowYTIndex: true,
                        });
                        navigate("/subscription");
                      }}
                    >
                      Purchase Plan
                    </UpgradePDfSpan>
                  </Col>
                ))}

              <Col sm="3">
                {/* <CancelButton
                  className="btn-warning"
                  onClick={() => {
                    //uploadYTURL();
                    setAllowedInputsForUser(false);
                  }}
                >
                  CANCEL
                </CancelButton> */}
              </Col>
            </Row>
            {pdfCountLimit ? (
              <>
                <Row>
                  <Col style={{ cursor: "not-allowed" }}>
                    <SavePDFButton type="button" disabled>
                      Create Chatbot
                    </SavePDFButton>
                    <UpgradePDfSpan
                      onClick={(e) => {
                        setPlanHighliter({
                          isBookHighliter: false,
                          bookLimit: 0,
                          isDeleteBookHighliter: false,
                          isFlashcardHighliter: false,
                          isCharacterLimit: false,
                          pdfLimit:
                            user &&
                            user.currentSubscription &&
                            user.currentSubscription.meta &&
                            user.currentSubscription.meta.pdfPageSupport
                              ? user.currentSubscription.meta.pdfPageSupport
                              : 500,
                          ispfdLimitHighliter: true,
                        });
                        navigate("/subscription");
                      }}
                    >
                      Upgrade Plan
                    </UpgradePDfSpan>
                    {/* <br /> */}
                    {/* <PlanSpan>Subscription plan page limit exceeded.</PlanSpan> */}
                  </Col>
                  <Col sm="3">
                    {/* <CancelButton
                  className="btn-warning"
                  onClick={() => {
                    //uploadYTURL();
                    setAllowedInputsForUser(false);
                  }}
                >
                  CANCEL
                </CancelButton> */}
                  </Col>
                </Row>
                <Row>
                  <Col style={{ cursor: "not-allowed" }}>
                    <PlanSpan>Subscription plan page limit exceeded.</PlanSpan>
                  </Col>
                </Row>
              </>
            ) : null}
          </BookFetchForm>
        )}
      </Formik>
      <UrlSelectionBox
        createUrlBook={createUrlBook}
        popupUrlContent={popupUrlContent}
        isLoggedIn={true}
      />
    </>
  );
};

export default CreateDropchatBook;
